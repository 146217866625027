import { FunctionComponent, ReactElement } from 'react';

import { useLocation } from 'react-router-dom';
import { ERoutes } from 'shared/enums/routes';

import AIToolsOnboardingLayout from './AIToolsOnboardingLayout';
import AuthLayout from './AuthLayout';

type TProps = {
	children: ReactElement;
};

const Layout: FunctionComponent<TProps> = (props): JSX.Element => {
	const { children } = props;

	const location = useLocation();

	const isAuthLayout = [
		ERoutes.login,
		ERoutes.registration,
		ERoutes.reset_password,
		ERoutes.change_password,
	].some((route) => location.pathname.includes(route));

	if (isAuthLayout) {
		return <AuthLayout>{children}</AuthLayout>;
	}

	if (location.pathname.includes(ERoutes.ai_tools_onboarding)) {
		return <AIToolsOnboardingLayout>{children}</AIToolsOnboardingLayout>;
	}

	return children;
};

export default Layout;
