import React, { FC, ReactElement, useEffect } from 'react';

import { isNil } from 'lodash';
import { Navigate, useLocation } from 'react-router-dom';
import { concatUrl, urlBuilder } from 'routers';
import { ReturnUseAuth, useAuth } from 'shared/api/auth.service';
import { ERoutes } from 'shared/enums/routes';
import WithLoader from 'shared/hoc/WithLoader';
import { useIsIncludesInPathname } from 'shared/hooks/useIsIncludesInPathname';
import PageLoader from 'shared/ui/PageLoader';

const AuthContext = React.createContext<ReturnUseAuth>(undefined!);

export const AuthProvider: FC<{ children: ReactElement }> = ({ children }) => {
	const auth = useAuth();
	const isPublicRoute = useIsIncludesInPathname({ path: ERoutes.public });

	useEffect(() => {
		if (isPublicRoute) return;

		(async (): Promise<void> => {
			try {
				if (auth.authed && !auth.user?.firstName) {
					await auth.refetchUser();
				} else {
					auth.setLoading(false);
				}
			} catch (e) {
				// eslint-disable-next-line no-console
				console.log('e', e);
			}
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [auth.authed, auth.user]);

	return (
		<AuthContext.Provider value={auth}>
			<WithLoader loading={auth.isUserLoading} center>
				{children}
			</WithLoader>
		</AuthContext.Provider>
	);
};

export default function AuthConsumer(): ReturnUseAuth {
	const context = React.useContext(AuthContext);
	if (context === undefined) {
		throw new Error('AuthConsumer must be used within a AuthProvider');
	}
	return context;
}

export const NoRequireAuth: FC<{ children: ReactElement }> = ({ children }) => {
	const { authed, user } = AuthConsumer();
	const location = useLocation();
	const url = urlBuilder(ERoutes.authorized_user, { engId: user?.currentEngagement?.id || '' });

	return !authed ? children : <Navigate to={url} state={{ path: location.pathname }} replace />;
};

export const RequireAuth: FC<{ children: ReactElement }> = ({ children }) => {
	const { authed } = AuthConsumer();
	const location = useLocation();

	return authed ? (
		children
	) : (
		<Navigate to={ERoutes.login} state={{ path: location.pathname }} replace />
	);
};

export const RequireAccess: FC<{ isHasAccess: boolean; children: ReactElement }> = ({
	children,
	isHasAccess,
}) => {
	if (isNil(isHasAccess)) {
		return <PageLoader />;
	}

	return isHasAccess ? (
		children
	) : (
		<Navigate to={concatUrl([ERoutes.access_denied_page], true)} replace />
	);
};
