import { FC, PropsWithChildren } from 'react';

import { Flex } from 'antd';
import bg from 'assets/images/onboarding.png';
import { ReactComponent as Logo } from 'assets/images/svg/logo.svg';
import { Navigate } from 'react-router-dom';
import { concatUrl } from 'routers';
import InitializationConsumer from 'shared/context/initialization';
import AuthConsumer from 'shared/guards/auth.guard';
import { useGetNavigatePathDueToPlan } from 'shared/hooks/useGetNavigatePathDueToPlan';

import styles from './AIToolsOnboardingLayout.module.scss';

const AIToolsOnboardingLayout: FC<PropsWithChildren> = ({ children }): JSX.Element => {
	const { user } = AuthConsumer();
	const { initialization } = InitializationConsumer();
	const { navigatePath } = useGetNavigatePathDueToPlan();

	if (initialization?.showToolsOnboarding === false) {
		const engId = user?.currentEngagement.id ?? user?.engagementIds[0];
		const url = concatUrl([engId!, navigatePath], true);
		return <Navigate to={url} replace />;
	}

	return (
		<Flex className={styles.container}>
			<div className={styles.imageContainer}>
				<img src={bg} alt="bg" className={styles.image} />
				<div className={styles.backdrop} />
				<div className={styles.disclaimerContainer}>
					<p className={styles.text}>
						“Every Strategic Consultant needs an AI Consulting Tool that will help them work faster
						and easier.”
					</p>
					<p className={styles.name}>Jamie L. Robinson</p>
					<p className={styles.name}>Founder & CEO at Corvus Link</p>
				</div>
			</div>
			<Flex className={styles.content} justify="center" align="center">
				<div className={styles.logo}>
					<Logo />
				</div>
				{children}
			</Flex>
		</Flex>
	);
};

export default AIToolsOnboardingLayout;
