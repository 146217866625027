import React, { FunctionComponent, ReactElement, useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useToggle } from 'react-use';
import {
	ReturnInitialization,
	getInitialization,
	useInitialization,
} from 'shared/api/initialization.service';
import { ERoutes } from 'shared/enums/routes';
import { SUBSCRIPTION_STATUSES } from 'shared/enums/subscriptionStatus';
import AuthConsumer from 'shared/guards/auth.guard';
import WithLoader from 'shared/hoc/WithLoader';
import { useIsIncludesInPathname } from 'shared/hooks/useIsIncludesInPathname';
import InitializationPropertiesModel from 'shared/models/initialization-properties.model';

const InitializationContext = React.createContext<ReturnInitialization>(undefined!);

export const InitializationProvider: FunctionComponent<{ children: ReactElement }> = ({
	children,
}) => {
	const initialization = useInitialization();
	const auth = AuthConsumer();
	const navigate = useNavigate();
	const isPublicRoute = useIsIncludesInPathname({ path: ERoutes.public });
	const [isLoading, setLoading] = useToggle(false);

	const { refetch, isLoading: isInitializationLoading } = useQuery({
		queryKey: ['initialization'],
		queryFn: () => getInitialization(),
		enabled:
			!initialization.initialization && auth?.authed && !!auth?.user?.firstName && !isPublicRoute,
		onSuccess: (data) => {
			initialization.setInitialization(new InitializationPropertiesModel(data));

			if (data?.showToolsOnboarding) {
				navigate(ERoutes.ai_tools_onboarding, { replace: true });
				return;
			}

			if (data?.subscriptionStatus !== SUBSCRIPTION_STATUSES.ACTIVE) {
				navigate(ERoutes.inactive_subscription, { replace: true });
				return;
			}
		},
	});

	useEffect(() => {
		if (isPublicRoute || !auth.authed) {
			setLoading(false);
			return;
		}

		setLoading(isInitializationLoading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isInitializationLoading, auth.authed]);

	return (
		<InitializationContext.Provider
			value={{
				...initialization,
				initializationLoading: isLoading,
				refetchInitialization: refetch,
			}}
		>
			<WithLoader loading={isLoading} center>
				{children}
			</WithLoader>
		</InitializationContext.Provider>
	);
};

export default function InitializationConsumer(): ReturnInitialization {
	const context = React.useContext(InitializationContext);
	if (context === undefined) {
		throw new Error('InitializationConsumer must be used within a InitializationProvider');
	}
	return context;
}
