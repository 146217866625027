import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';
import addGTMEvent, { EGTMEvent } from 'shared/lib/addGTMEvent';

const usePageTracking = (): void => {
	const location = useLocation();

	useEffect(() => {
		addGTMEvent({
			event: EGTMEvent.PAGEVIEW,
			page: location.pathname + location.search,
		});
	}, [location]);
};

export default usePageTracking;
