declare global {
	interface Window {
		dataLayer: object[];
	}
}

export enum EGTMEvent {
	PAGEVIEW = 'pageview',
	AI_ENTITY_CREATED = 'ai_entity_created',
	MANUAL_ENTITY_CREATED = 'manual_entity_created',
}

const addGTMEvent = <T extends { event: EGTMEvent }>(data: T): void => {
	// TODO: uncomment when testing is done (MG)
	// if (process.env.REACT_APP_ENVIRONMENT !== 'prod') {
	// 	return;
	// }

	window.dataLayer = window.dataLayer || [];
	window.dataLayer.push(data);
};

export default addGTMEvent;
